<template>
	<div class="p-4">
		<div class="flex justify-between">
			<h1>Clients Information</h1>
		<button class="btnprimary" @click="addClient()">Add Client</button>
		</div>
		<div class="mt-4">
			<table class="font-opensans" v-if="allTenants.length > 0">
				<thead>
					<tr>
						<th>Organisations</th>
						<th>Active</th>
						<th class="header cursor-pointer" v-for="app in apps" :key="app.id">
							<div>
								{{ app.appName }}
							</div>
						</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="tenant in allTenants" :key="tenant.id">
						<td>{{ tenant.organizationName }}</td>
						<td><input type="checkbox" v-model="tenant.isActive" @change="onActivateTenant(tenant)" /></td>
						<td v-for="app in tenant.apps" :key="app.id">
							<div>
								<input type="checkbox" :disabled="!tenant.isActive" v-model="app.isActive" @change="onUpdateApp(tenant, app)" />
							</div>
						</td>
						<td @click="oneditClient(tenant)"><img src="@/assets/editIcon.svg" alt="image" class="w-6 h-6"/></td>
					</tr>
				</tbody>
			</table>

			<div v-if="allTenants == 0" class="text-center">
				<div class="mainerrorblock">
					<div class="mt-8">
						<img src="../assets/noData.svg" alt="image" class="imgSizing" style="width:320px"/>
						<h5 class="no-data">No Clients.Please add one.</h5>
					</div>
				</div>
			</div>
			<div class="buttonContainer  flex justify-between mt-2" v-if="allTenants.length > pageSize">
				<div class="total text-black">Total {{ allTenants.length}}</div>
				<div class="pagecontainer flex justify-center cursor-pointer">
					<div class="pagination">
					<img src="@/assets/leftArrowFilled.svg"  class="w-5 h-5 mt-0.5 " :class="cantGoBack ? 'disabled' : ''" @click="prevPage" />
					</div>
					<div class="text-black mx-2">
						{{ (this.currentPage - 1) * this.pageSize + 1 }} -
						{{ this.currentPage * this.pageSize }}
					</div>
					<div class="pagination">
						<img src="@/assets/rightArrowFilled.svg" class="w-5 h-5 mt-0.5" :class="cantGoForward ? 'disabled' : ''" @click="nextPage" />
					</div>
					
				</div>
			</div>
		</div>
		<spinner v-if="isLoader" />
	</div>
</template>
<style scoped>
.imgSizing{
	width: 320px;
	margin: 0 auto;
}
.no-data{
	color: #7e7e7e;
	font-weight: 500;
	font-size: 28px;
	margin-top: 2rem;
}
td{
	padding: 10px 25px;
}
th:last-child,
td:last-child{
	width: 120px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { toast } from '../main';
import spinner from "@/components/spinner.vue"
export default defineComponent({
	data(): any {
		return {
			allTenants: [],
			apps: [],
			isLoader:false,
			activateTenants: [],
			currentSort: 'name',
			currentSortDir: 'asc',
			pageSize: 12,
			currentPage: 1
		};
	},

	components:{
		spinner
	},
	computed: {
		sortedData: function ():any {
			return [...this.allTenants]
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		cantGoBack():any {
			return this.currentPage === 1;
		},
		cantGoForward() :any{
			return this.teamsList.length / this.pageSize <= this.currentPage;
		}
	},

	methods: {
			filteredFunction(filterColumn: any, columnName: any) {
			this.teamsList = this.dummyList.filter((item: any) => {
				for (var property in item) {
					if (property == columnName) {
						return item[property].toLowerCase().includes(filterColumn.toLowerCase());
					}
				}
			});
		},
		nextPage: function () {
			if (this.currentPage * this.pageSize < this.teamsList.length) this.currentPage++;
		},
		prevPage: function () {
			if (this.currentPage > 1) this.currentPage--;
		},
		getAllTenants() {
			this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
				this.allTenants = res.data;
				this.apps = this.allTenants[0].apps;
			});
		},
		oneditClient(tenant: any): any {
			this.$router.push({ name: 'edit-organisation', params: { organisationId: tenant._id } });
		},
		addClient(){
			this.$router.push({ name: 'add-organisation' })
		},

		onActivateTenant(tenant: any) {
			this.isLoader = true
			this.$http
			.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/` + tenant._id + `/${tenant.isActive ? 'activate' : 'inactivate'}`)
			.then(() => {
				this.isLoader = false
				toast.success(`${tenant.isActive ? 'Succesfully Activated' : 'Succesfully In-Activated'}`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				
			})
			.catch(() => {
				this.isLoader = false
				toast.error(` Not Activated`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			});
		},
		onUpdateApp(tenant: any, app: any) {
			this.isLoader = true
			this.$http
				.post(`${process.env.VUE_APP_AUTH_API_URL}/tenant/` + tenant._id + `/app/update`, app)
				.then(() => {
					this.isLoader =false
					toast.success(`Successfully Updated.`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					
				})
				.catch(() => {
					this.isLoader =false
					toast.error(` Not Updated`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					
				});
		}
	},
	mounted() {
		this.getAllTenants();
	}
});
</script>