<template>
	<div class="flex justify-center items-center h-screen">
		<div style="border-top-color: transparent" class="w-20 h-20 border-4 border-blue-400 border-solid rounded-full animate-spin"></div>
		<div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
	</div>
</template>
<style scoped>
</style>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({});
</script>