<template>
	<div class="p-4">
		<div>
			<h1>Join Us</h1>
		</div>
		<div class="mt-4">
			<div class="card  rounded p-4">
			<form @submit.prevent="onSubmit">
				<div class="flex row w-full mt-4">
					<div class="w-1/4">
						<label>Organization Name: </label>
					</div>
					<div class="w-3/4">
						<input
							type="text"
							class="inputboxstyling"
							id="inputcontrolSource"
							name="inputcontrolSource"
							placeholder="Organization Name"
							autocomplete="off"
							:class="{
								'is-invalid': v$.onboarding.organisationName.$error
							}"
							v-model="onboarding.organisationName"
						/>
						<div v-if="v$.onboarding.organisationName.$error">
							<div v-if="v$.onboarding.organisationName.required" class="invalid-feedback">Organisation Name is required</div>
						</div>
					</div>
				</div>

				<div class="flex row w-full mt-4">
					<div class="w-1/4">
						<label>Address: </label>
					</div>

					<div class="w-3/4">
						<input type="text" class="inputboxstyling" id="inputcontrolSource" name="inputcontrolSource" placeholder="Address" autocomplete="off" :class="{ 'is-invalid': v$.onboarding.address.$error }" v-model="onboarding.address" />
						<div v-if="v$.onboarding.address.$error">
							<div v-if="v$.onboarding.address.required" class="invalid-feedback">Address is required</div>
						</div>
					</div>
				</div>

				<div class="flex row w-full mt-4">
					<div class="w-1/4">
						<label>Phone: </label>
					</div>
					<div class="w-3/4">
						<input type="text" class="inputboxstyling" id="inputcontrolSource" name="inputcontrolSource" placeholder="Phone" autocomplete="off" :class="{ 'is-invalid': v$.onboarding.phoneNumber.$error }" v-model="onboarding.phoneNumber" />
                        <div v-if="v$.onboarding.phoneNumber.$error">
							<span v-if="v$.onboarding.phoneNumber.numeric.$invalid" class="invalid-feedback">Number is invalid</span>
							<div v-if="v$.onboarding.phoneNumber.required && !v$.onboarding.phoneNumber.numeric.$invalid" class="invalid-feedback">Phone is required</div>
						</div>
					</div>
				</div>

				<div class="flex row w-full mt-4">
					<div class="w-1/4">
						<label>Website: </label>
					</div>
					<div class="w-3/4">
						<input type="text" class="inputboxstyling" id="inputcontrolSource" name="inputcontrolSource" placeholder="Website" autocomplete="off" :class="{ 'is-invalid': v$.onboarding.website.$error }" v-model="onboarding.website" />
						<div v-if="v$.onboarding.website.$error">
							<div v-if="v$.onboarding.website.required " class="invalid-feedback">Website is required</div>
						</div>
					</div>
				</div>
				<div class="flex row w-full mt-4">
					<div class="w-1/4">
						<label>Contact Person: </label>
					</div>
					<div class="w-3/4">
						<input type="text" class="inputboxstyling" id="inputcontrolSource" name="inputcontrolSource" placeholder="Contact Person" autocomplete="off" :class="{ 'is-invalid': v$.onboarding.contactPerson.$error }" v-model="onboarding.contactPerson" />
						<div v-if="v$.onboarding.contactPerson.$error">
							<div v-if="v$.onboarding.contactPerson.required" class="invalid-feedback">Contact Person is required</div>
						</div>
					</div>
				</div>
				<div class="flex row w-full mt-4">
					<div class="w-1/4">
						<label>Organisation E-mail: </label>
					</div>
					<div class="w-3/4">
						<input
							type="text"
							class="inputboxstyling"
							id="inputcontrolSource"
							name="inputcontrolSource"
							placeholder="Contact Person E-mail"
							autocomplete="off"
							:class="{
								'is-invalid': v$.onboarding.contactPersonEmail.$error
							}"
							v-model="onboarding.contactPersonEmail"
						/>
						<div v-if="v$.onboarding.contactPersonEmail.$error">
							<div v-if="v$.onboarding.contactPersonEmail.required" class="invalid-feedback">Contact Person Email is required</div>
						</div>
					</div>
				</div>
			<div class="flex row w-full mt-4">
					<div class="w-1/4">
						<label>Organization Logo</label>
					</div>
					<div class="w-3/4">
						<input type="text" class="inputboxstyling" id="inputcontrolSource" name="inputcontrolSource" placeholder="Organization Logo" autocomplete="off" v-model="onboarding.organisationLogo" />
						<!-- <div v-if="v$.onboarding.organisationLogo.$error">
						<div v-if="v$.onboarding.organisationLogo.required" class="invalid-feedback"> Organisation logo required</div>
					</div> -->
					</div>
				</div>
				<div class="flex row w-full mt-4">
					<div class="w-1/4">
						<label>Display Name: </label>
					</div>
					<div class="w-3/4">
						<input
							type="text"
							class="inputboxstyling"
							id="inputcontrolSource"
							name="inputcontrolSource"
							placeholder="Display Name"
							autocomplete="off"
							:class="{
								'is-invalid': v$.onboarding.displayName.$error
							}"
							v-model="onboarding.displayName"
						/>
						<div v-if="v$.onboarding.displayName.$error">
							<div v-if="v$.onboarding.displayName.required" class="invalid-feedback">Display Name is Required</div>
						</div>
					</div>
				</div>
					<div class="flex justify-center mt-4">
						<button class="btnprimary mr-6" v-if="pageType =='add'">
							<span>Save</span>
						</button>
						<button class="btnprimary mr-6" v-else>
							<span>Update</span>
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<style scoped>
label {
	float: left;
	width: 100%;
	text-align: right;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.invalid-feedback {
	color: red;
}
.is-invalid {
	border: 1px solid red !important;
}
</style>
<script lang="ts">
import { required,email, helpers } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
const numeric = helpers.regex( /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);

import { defineComponent } from 'vue';
import { toast } from '../main';
export default defineComponent({
	name: 'app',
	data() :any{
		return {
            v$: useVuelidate(),
			onboarding: {
				organisationName: '',
				address: '',
				phoneNumber: '',
				website: '',
				domain: '',
				contactPerson: '',
				contactPersonEmail: '',
				subDomain: '',
				displayName:'',
			},
			isLoading: false,
			pageType:'',
			orgActive:false,
			allApps:[]
		};
	},
	validations() {

        return{
	onboarding: {
			organisationName: { required },
			address: { required },
			contactPerson: { required },
			contactPersonEmail: { required, email },
			phoneNumber: { required ,numeric},
			website: { required },
			displayName:{required}
		}
        }
		
	},
	computed: {
		...mapGetters({
			userInfo: 'userInfo'
		})
	},
	methods: {
		async addOrganization(payload: any) {
			await this.$http
				.post(`${process.env.VUE_APP_AUTH_API_URL}/organization/add`, payload)
				.then((res:any) => {
					console.log("response",res.data.errorStatus)
					if(res.data.errorStatus){
							toast.error(res.data.errorStatusData, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						this.$router.back();
					}
					if (res.status == 200) {
						toast.success(`Organisations Added.`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						this.$router.back();
					}
				})
				.catch((err:any) => {
					toast.error(err, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		async updateOrganization(payload: any) {
			await this.$http
				.post(`${process.env.VUE_APP_AUTH_API_URL}/organization/${this.$route.params.organisationId}/update`, payload)
				.then((res:any) => {
					console.log("response",res.data.errorStatus)
					if(res.data.errorStatus){
							toast.error(res.data.errorStatusData, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						this.$router.back();
					}
					if (res.status == 200) {
						toast.success(`Organisations Updated.`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						this.$router.back();
					}
				})
				.catch((err:any) => {
					toast.error(err, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		onSubmit() {
            console.log("save")
			this.submitted = true;
			this.v$.$touch();
			if (this.v$.$invalid) {
                 console.log("savedsdd")
				return;
			}
			let fullDomain = this.onboarding.contactPersonEmail.substring(this.onboarding.contactPersonEmail.lastIndexOf("@") + 1);
            let orgName = fullDomain.split('.')[0];
			let urldomain=this.onboarding.website.startsWith("http://") ? 'http://' : 'https://'
			let website=this.onboarding.website.replace(urldomain, '');
			if(this.onboarding.organisationLogo=='' || this.onboarding.organisationLogo==undefined ||this.onboarding.organisationLogo.includes('clearbit')){
				this.onboarding.organisationLogo=`https://logo.clearbit.com/${website}`
			}

			let payload:any = {
				organizationName: this.onboarding.organisationName,
				orgLogo: '',
				address: this.onboarding.address,
				contactNumber: this.onboarding.phoneNumber,
				websiteUrl: this.onboarding.website,
				contactPerson: this.onboarding.contactPerson,
				tenantName: orgName,
				displayName:this.onboarding.displayName,
				organizationLogo: this.onboarding.organisationLogo,
				contactPersonEmail:this.onboarding.contactPersonEmail,
				
			};

			if(this.pageType=='add'){
				payload.isActive=false
				payload.apps=[{appId:10001,appName:'Monitor',isActive:false},{appId:10002,appName:'Training',isActive:false}],
				payload.createdBy= this.userInfo._id,
				payload.createdAt= new Date(),
				this.addOrganization(payload);
			}
			else{
				payload.isActive=this.orgActive
				payload.apps=this.allApps
				payload.updatedBy= this.userInfo._id,
				payload.updatedAt= new Date(),
				this.updateOrganization(payload)
			}
			// console.log('payload',payload);
			
		},
		async getSingleOrganization() {
			this.spinnerLoading = true;
			await this.$http
				.get(`${process.env.VUE_APP_AUTH_API_URL}/organization/${this.$route.params.organisationId}/get`)
				.then((res: any) => {
					this.spinnerLoading = false;
					this.onboarding = res.data[0];
					let orgName
					this.onboarding.organisationName = this.onboarding.organizationName
					this.onboarding.phoneNumber = this.onboarding.contactNumber
					this.onboarding.website =this.onboarding.websiteUrl
					this.onboarding.organisationLogo = this.onboarding.organizationLogo
					this.allApps=this.onboarding.apps
					this.orgActive= this.onboarding.isActive

					
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
	},
	mounted() {
		this.pageType = this.$route.fullPath.includes('add') ? 'add' : 'edit';
		console.log(this.$route)
		if (this.$route.params.organisationId) {
			console.log("inside")
			this.getSingleOrganization()
		}
	}
});
</script>