<template>
	<div class="fixed bg-primary sidenav flex flex-col justify-between h-full z-50" :class="{ 'w-40': isSidebarToggle, 'w-10': !isSidebarToggle }">
		<ul>
			<li v-for="(menu, menuIndex) in menus" :key="menuIndex" @click="navigate(menu.optionName, menu.optionRoute)" class="cursor-pointer hover:bg-white">
				<div :class="selectedOptionName == menu.optionName ? 'bg-whitegrey text-black font-bold' : 'text-lightgrey font-normal'"   class="a"  @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
					<a class="flex navchild" :class="{ 'px-2 py-1': isSidebarToggle, 'pl-2 pr-1.5 py-1': !isSidebarToggle }">
						<img :src="menu.defaultoptionImage" class="h-5 mr-2.5 icon" :class="{ 'w-3 ': isSidebarToggle, 'w-4': !isSidebarToggle }" v-if="selectedOptionName != menu.optionName && !menu.hover" />
						<img :src="menu.colouredoptionImage" class="h-5 mr-2.5 icon" :class="{ 'w-3': isSidebarToggle, 'w-4': !isSidebarToggle }" v-else />
						<div class="item-name leading-5 text-sm "  v-if="isSidebarToggle">{{ menu.displayOptionName }}</div>
					</a>
				</div>
			</li>
		</ul>
		<ul class="bottomUl mb-16">
			<li class="relative">
				<hr class="mb-4" />
				<img src="@/assets/leftarrow.svg" class="h-8 ml-32 icon leftarrow absolute cursor-pointer" alt="" @click="toggleSideMenuBar()" v-if="isSidebarToggle" />
				<img src="@/assets/rightarrow.svg" class="h-8 icon ml-2 rightarrow absolute cursor-pointer" alt="" @click="toggleSideMenuBar()" v-else />
			</li>	
		</ul>
		
	</div>
	<div class="fixed shadowapps z-50 bg-white w-40 px-2 py-1 min-h-30 flex flex-col justify-center" v-if="appsVisible">
			<div class="flex flex-row items-center cursor-pointer">
				<!-- <div class="flex flex-col mr-1 w-2/4 items-center" @click="triggerNav('monitor')">
					<img src="@/assets/monitoring.svg" alt="" class="icn h-6 w-6 ml-2" />
					<div class="nav-item-label-container">
						<div class="nav-item-label">Monitor</div>
					</div>
				</div> -->
				

				<div class="flex flex-row w-2/4 items-center cursor-pointer" @click="triggerNav('training')">
				<img src="@/assets/training.svg" alt="" class="icn h-8 w-8" />
				<div class="nav-item-label-container ml-4">
					<div class="text-sm font-semibold">Training</div>
					<div class="active-border"></div>
				</div>
			</div>
			
				<!-- <div class="flex flex-col w-2/4 items-center cursor-pointer" @click="triggerNav('workflow')">
					<img src="@/assets/monitoring.svg" alt="" class="icn h-6 w-6 ml-2" />
					<div class="nav-item-label-container">
						<div class="nav-item-label active">Workflow</div>
						<div class="active-border"></div>
					</div>
				</div> -->
			</div>
		</div>
</template>
<style scoped>
.min-h-30{
	height: 50px;
}
.bg-white{
	background-color: #f9f9fb;
	padding-left:7px;
}
.w-40{
	width: 170px;
}
.w-3{
	width:15px
}
.shadow {
	--tw-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.25);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.leftarrow ,
.rightarrow{
	height: 1.25rem;
	width: 1.25rem;
	margin-top: -27px;
}

.a:hover{
	color:black;
	font-weight: 400;
}

.icon:hover {
transition: none !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
	data() {
		return {
			isSidebarToggle: true,
			appsVisible:false,
				menus: [
				// {
				// 	optionName: 'onboarding',
				// 	optionRoute: 'onboarding',
				// 	defaultoptionImage: require('@/assets/onBoardingDefault.svg'),
				// 	colouredoptionImage: require('@/assets/onBoardingColoured.svg'),
				// 	displayOptionName: 'On Boarding'
				// },
				{
					optionName: 'organisations',
					defaultoptionImage: require('@/assets/organisationDefault.svg'),
					colouredoptionImage: require('@/assets/organisationColoured.svg'),
					optionRoute: 'organisations',
					displayOptionName: 'Organisations',
				},
				// {
				// 	optionName: 'organisationsactivity',
				// 	defaultoptionImage: require('@/assets/organisationDefault.svg'),
				// 	colouredoptionImage: require('@/assets/organisationColoured.svg'),
				// 	optionRoute: 'organisationsactivity',
				// 	displayOptionName: 'User Activity',
				// }
				]
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo',toggleState:'toggle' }),
		getlocation() {
			return window.location.origin;
		},
		...mapGetters({ selectedOptionName: 'val' })
	},
	mounted() {
		console.log("logged")
		emitter.on('toggle-sidebar', (isToggle: any) => {
		this.isSidebarToggle = isToggle;
		 });
		emitter.on('apps-sidemenubar', (isToggle: any) => {
		this.appsVisible = isToggle;
 		});
		emitter.on('apps', (isToggle: any) => {
 		this.appsVisible = isToggle;
 		});
		this.isSidebarToggle=!this.toggleState
		this.selectedOptionName = this.menus[0].optionRoute
 	},

	methods: {
		triggerNav(app:string) {
			if(app=="workflow"){
				window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_WORKFLOW_WEBAPP_URL}`;
			}else{
				window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${app == 'monitor' ? process.env.VUE_APP_MONITOR_WEBAPP_URL : process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
			}
			// window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${app == 'monitor' ? process.env.VUE_APP_MONITOR_WEBAPP_URL : process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
		},
		triggerNavToSettings() {
			window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ORG_WEBAPP_URL}`;
		},
		toggleSideMenuBar() {
			this.isSidebarToggle = !this.isSidebarToggle;
			emitter.emit('toggle-sidebar', this.isSidebarToggle);
		},
		navigate(name:any, routerOption:any) {
			this.$store.dispatch('selectedRoute', name);
			this.$router.push({ name: routerOption });
			this.selectedOptionName = name
		},
	}
});
</script>